import React, { Component } from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

export default class Contacto extends Component {
  componentDidMount() {
    this.renderGoogleMaps();
  }

  renderGoogleMaps() {
    const GoogleMapsLoader = require('google-maps');

    GoogleMapsLoader.KEY = 'AIzaSyBa6ZkUrRXNu_uZ95hKK4zy0S6HlF6mABY';
    GoogleMapsLoader.load(google => {
      const center = new google.maps.LatLng(-17.376453, -66.157273);
      const map = new google.maps.Map(this.mapEl, {
        center,
        scrollwheel: false,
        zoom: 17,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
      });
      const marker = new google.maps.Marker({
        map,
        position: center,
      });
    });
  }

  render() {
    return (
      <Layout className="uk-background-secondary">
        <SEO
          title="Contáctenos"
          keywords={[
            'escuela de aviacion',
            'piloto privado',
            'airline pilot academy',
            'aviacion',
            'cochabamba',
            'bolivia',
          ]}
        />
        <div className="uk-section-default">
          <div
            className="map"
            ref={el => (this.mapEl = el)}
            uk-viewport-width="true"
          />
        </div>
        <div className="uk-section-secondary">
          <div className="uk-container uk-padding-large">
            <div className="uk-child-width-1-3@m" uk-grid="true">
              <div>
                <h3 className="uk-text-uppercase uk-text-bold">
                  Tel&eacute;fonos
                </h3>
                <p className="uk-margin-remove">
                  (+591) 4 4799698
                  <br/>
                  (+591) 737 64241
                </p>
              </div>
              <div>
                <h3 className="uk-text-uppercase uk-text-bold">
                  Direcci&oacute;n
                </h3>
                <p className="uk-margin-remove">
                  c/Pedro Blanco 282 casi Av. Santa Cruz
                  <br />
                  Zona Queru Queru
                  <br />
                  Cochabamba, Bolivia
                </p>
              </div>
              <div>
                <h3 className="uk-text-uppercase uk-text-bold">
                  email
                </h3>
                <p className="uk-margin-remove">
                  <a href="mailto:info@apaaviacion.com?subject=Consulta sobre cursos">info@apaaviacion.com</a>
                </p>
              </div>
            </div>
            <br />
          </div>
        </div>
      </Layout>
    );
  }
}
